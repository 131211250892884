import React, { Component, useEffect, useState } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage, Button } from '../../components';

const { Money } = sdkTypes;

import labelRseLogo from '../../assets/logo-label-rse.png';
import videoconference from '../../assets/icon/videoconferenceWhite.svg';

import css from './ListingCard.module.css';
import { packages } from '../../marketplace-custom-config';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find((c) => c.key === key);
};

const getFormulasMinimumPrice = (searchParams, allPrices) => {
  if (!searchParams?.pub_prices) return null;
  const priceKeys = searchParams.pub_prices
    ?.split(':')[1]
    ?.replace('has_any:', '')
    ?.split(',')
    ?.map((s) => s.trim());

  const matchingPrices = allPrices
    .filter((price) => priceKeys.includes(price.key))
    .map((price) => price.value);

  if (matchingPrices.length === 0) {
    return null;
  }

  return Math.min(...matchingPrices);
};

const isPackagesFormulas = (searchParams, packages) => {
  if (!searchParams?.pub_prices) return false;
  const priceKeys = searchParams.pub_prices
    ?.split(':')[1]
    ?.replace('has_any:', '')
    ?.split(',')
    ?.map((s) => s.trim());

  return priceKeys?.some((key) => packages.includes(key));
};
class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = (props) => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
    onQueryChildListings,
    searchParams,
  } = props;
  const [childListingList, setChildListingList] = useState([]);

  const getChildListings = async (id) => {
    try {
      const res = await onQueryChildListings(id);
      setChildListingList(res.filter((i) => i.attributes.state === 'published'));
    } catch (error) {
      console.log('Error with get child listings');
    }
  };

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;

  const { title = '', price, publicData } = currentListing.attributes;
  const { singleBedroomsCount, twinBedroomsCount, singleBedsCount } = publicData;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const certificateOptions = findOptionsForSelectFilter('certificate', filtersConfig);
  const certificate = publicData
    ? getCertificateInfo(certificateOptions, publicData.certificate)
    : null;
  const listingCategory = publicData.category;
  const isRse = publicData.rse;
  const isVenue = listingCategory === 'venue';
  const isShuttle = listingCategory === 'shuttle';
  const isCatering = listingCategory === 'catering';
  const isPackages = isPackagesFormulas(searchParams, packages);

  useEffect(() => {
    if (isVenue) getChildListings(id);
  }, [id, isVenue]);

  const allPrices = [];

  if (childListingList.length > 0) {
    childListingList.map((i) => {
      const prices = i.attributes.publicData.prices;
      const priceScheme = i.attributes.publicData.priceScheme;

      if (prices && prices.length > 0) {
        prices.map((item) => {
          Object.values(priceScheme[item])
            .filter((i) => i !== null)
            .map((i) => {
              if (i.amount) {
                allPrices.push({ key: item, value: i.amount });
              }
            });
        });
      }
    });
  }

  const formulasMinimumPrice = getFormulasMinimumPrice(searchParams, allPrices);

  const minPrice = currentListing && currentListing.attributes.publicData.minPrice;
  const priceCustom = minPrice && new Money(minPrice * 100, config.currency);
  const formattedCustomPrice = minPrice ? formatMoney(intl, priceCustom) : null;
  const formattedFormulasPrice = formulasMinimumPrice
    ? formatMoney(intl, new Money(formulasMinimumPrice, config.currency))
    : null;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
      ? 'ListingCard.perDay'
      : 'ListingCard.perUnit';

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
          <div className={css.iconContainer}>
            {isRse ? <img src={labelRseLogo} alt="labelRseLogo" className={css.iconRse} /> : null}
            {isVenue ? (
              <div className={css.iconConference}>
                <img src={videoconference} alt="conference icon" className={css.conferenceImg} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={css.info}>
        {isVenue ? (
          <div className={css.amountChildListings}>
            <FormattedMessage
              id="ListingCard.childListingsAmount"
              values={{ amount: childListingList.length }}
            />
          </div>
        ) : null}
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div>
            {!!singleBedroomsCount &&
              `${singleBedroomsCount} ${intl.formatMessage({
                id: 'bedroom(s)',
              })}`}
            {!!twinBedroomsCount &&
              ` - ${twinBedroomsCount} ${intl.formatMessage({
                id: 'twin(s)',
              })}`}
            {!!singleBedsCount &&
              ` - ${singleBedsCount} ${intl.formatMessage({
                id: 'singleBed(s)',
              })}`}
          </div>
          <div className={css.certificateInfo}>
            {certificate && !certificate.hideFromListingInfo ? (
              <span>{certificate.label}</span>
            ) : null}
          </div>
        </div>
        {isVenue || isShuttle || isCatering ? (
          <div className={css.price}>
            <div className={css.priceValue}>
              <FormattedMessage
                id={
                  isPackages
                    ? 'SpaceCard.formulasPrice'
                    : isCatering
                      ? 'ListingCard.cateringPrice'
                      : 'ListingCard.venuePrice'
                }
                values={{ price: formattedFormulasPrice || formattedCustomPrice }}
              />
            </div>
          </div>
        ) : (
          <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              <FormattedMessage
                id={'ListingCard.venuePrice'}
                values={{ price: formattedPrice || '-' }}
              />
            </div>
            {/* <div className={css.perUnit}>
              <FormattedMessage id={unitTranslationKey} />
            </div> */}
          </div>
        )}
      </div>
      <div>
        <Button style={{ borderRadius: 0 }}>
          <FormattedMessage id="ListingCard.viewListing" />
        </Button>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
